import React from 'react';
import { makeStyles, Theme, createStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Container, Box, Typography, Link, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { ExtLink, CredibleResources } from 'components';
import { SitePage } from 'components/layout';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(8),
    },
    title: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(2),
    },
    subtitle: {
      marginBottom: theme.spacing(2),
    },
    paper: {
      backgroundColor: '#f8f9fa',
    },
    accordion: {
      border: 'none',
      backgroundColor: 'transparent',
    },
    MuiAccordionroot: {
      '&.MuiAccordion-root:before': {
        backgroundColor: 'transparent',
      },
    },
  }),
);

interface LegalServices {
  state: string;
  name: string;
  url: string;
  phone: string;
}

const legalServices: LegalServices[] = [
  { state: 'VIC', name: 'Victoria Legal Aid', url: 'https://www.legalaid.vic.gov.au', phone: '1300 792 387' },
  { state: 'NSW', name: 'Law Access', url: 'https://www.lawaccess.nsw.gov.au', phone: '1300 888 529' },
  { state: 'QLD', name: 'Queensland Legal Aid', url: 'https://www.legalaid.qld.gov.au/Home', phone: '1300 651 188' },
  {
    state: 'ACT',
    name: 'ACT Legal Aid',
    url: 'https://www.legalaidact.org.au/what-we-do/legal-aid-helpline',
    phone: '1300 654 314',
  },
  { state: 'TAS', name: 'Legal Aid Tasmania', url: 'https://www.legalaid.tas.gov.au', phone: '1300 366 611' },
  {
    state: 'WA',
    name: 'Legal Aid WA',
    url: 'https://www.legalaid.wa.gov.au/find-legal-answers',
    phone: '1300 650 579',
  },
  {
    state: 'SA',
    name: 'Legal Services Commission of SA',
    url: 'https://lsc.sa.gov.au/cb_pages/legal_advice.php',
    phone: '1300 366 424',
  },
  { state: 'NT', name: 'NT Legal Aid', url: 'https://www.legalaid.nt.gov.au', phone: '1800 019 343' },
];

export default function index() {
  const classes = useStyles();
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down('xs'));
  const md = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <SitePage>
      <Container className={classes.root}>
        <Typography color="primary" variant="h4" align="left" className={classes.title}>
          Useful Resources for Parents & Carers
        </Typography>

        <Box py={2} mb={8}>
          <Typography paragraph>
            If you or your child want more information about depression and anxiety, or would like to speak to someone
            over the phone, please check out the following organisations and helplines. You’ll also find some other
            resources referred to in the PiP modules.
          </Typography>

          <Typography paragraph>
            To contact the PiP team, please email{' '}
            <Link href="mailto: med-pip-plus@monash.edu">med-pip-plus@monash.edu</Link> or phone{' '}
            <Link href="tel:0399051250">(03) 9905 1250</Link>.
          </Typography>

          <Box>
            <Typography color="primary" variant="h4" className={classes.title}>
              Mental health resources for parents/carers
            </Typography>

            <Box mb={4}>
              <Typography variant="h6">
                <ExtLink href="https://www.beyondblue.org.au/" color="primary">
                  Beyond Blue
                </ExtLink>
              </Typography>

              <Typography paragraph>
                Phone: <Link href="tel:1300224636">1300 224 636</Link>.
              </Typography>

              <Typography paragraph>
                Email or chat online at{' '}
                <ExtLink href="www.beyondblue.org.au/getsupport">www.beyondblue.org.au/getsupport</ExtLink>
              </Typography>

              <Typography paragraph>
                Learn more about depression and anxiety, or talk it through with support services at Beyond Blue.
              </Typography>
            </Box>

            <Box mb={4}>
              <Typography variant="h6">
                <ExtLink href="https://www.headtohealth.gov.au/" color="primary">
                  Head to Health
                </ExtLink>
              </Typography>

              <Typography paragraph>
                Head to Health provides access to trusted mental health and wellbeing information, online programs and
                digital resources.
              </Typography>
            </Box>

            <Box mb={4}>
              <Typography variant="h6">
                <ExtLink href="https://headspace.org.au/eheadspace/" color="primary">
                  eheadspace
                </ExtLink>
              </Typography>
              <Typography paragraph>
                Phone: <Link href={'tel:1800650890'}>1800 650 890</Link>.
              </Typography>
              <Typography paragraph>
                eheadspace is a confidential, free and secure space where young people aged 12 to 25 or their family can
                chat, email or speak on the phone with a qualified youth mental health professional.
              </Typography>
            </Box>

            <Box mb={4}>
              <Typography variant="h6">
                <ExtLink href="https://www.lifeline.org.au/" color="primary">
                  Lifeline
                </ExtLink>
              </Typography>
              <Typography paragraph>
                Phone: <Link href={'tel:131114'}>13 11 14</Link>.
              </Typography>
              <Typography paragraph>
                Lifeline provides access to crisis support, suicide prevention and mental health support services.
              </Typography>
            </Box>

            <Box mb={4}>
              <Typography variant="h6">
                <ExtLink href="https://www.psychology.org.au/Find-a-Psychologist" color="primary">
                  Find a Psychologist
                </ExtLink>
              </Typography>
              <Typography paragraph>
                If you would like to find a registered psychologist in your area, the{' '}
                <ExtLink href={'https://www.psychology.org.au/Find-a-Psychologist'}>
                  Australian Psychological Society Find a Psychologist service
                </ExtLink>{' '}
                can assist with this. You can also speak to your GP about getting a referral to a psychologist.
              </Typography>
            </Box>
          </Box>

          <Box>
            <Typography color="primary" variant="h4" className={classes.title}>
              Mental health resources for young people
            </Typography>

            <Box mb={4}>
              <Typography variant="h6">
                <ExtLink href="https://www.beyondblue.org.au/who-does-it-affect/young-people" color="primary">
                  Youth Beyond Blue
                </ExtLink>
              </Typography>
              <Typography paragraph>
                Phone: <Link href={'tel:1300224636'}>1300 22 4636</Link>.
              </Typography>
              <Typography paragraph>
                Youth Beyond Blue’s website for young people aged 12 to 15. If you need to talk to someone, find more
                information or find out where you can go to see someone contact the beyondblue support service.
              </Typography>
            </Box>

            <Box mb={4}>
              <Typography variant="h6">
                <ExtLink href="https://headspace.org.au/" color="primary">
                  Headspace
                </ExtLink>
              </Typography>
              <Typography paragraph>
                Headspace helps young people aged 12 to 25 who are going through a tough time, providing support for
                problems like depression, anxiety, bullying and body image.
              </Typography>
            </Box>

            <Box mb={4}>
              <Typography variant="h6">
                <ExtLink href="https://www.eheadspace.org.au" color="primary">
                  eheadspace
                </ExtLink>
              </Typography>
              <Typography paragraph>
                Phone: <Link href={'tel:1800650890'}>1800 650 890</Link>.
              </Typography>
              <Typography>
                eheadpsace is a confidential, free and secure space where young people aged 12 to 25 or their family can
                chat, email or speak on the phone with a qualified youth mental health professional.
              </Typography>
            </Box>

            <Box mb={4}>
              <Typography variant="h6">
                <ExtLink href="https://kidshelpline.com.au/" color="primary">
                  Kids Helpline
                </ExtLink>
              </Typography>
              <Typography paragraph>
                Phone: <Link href={'tel:1800551800'}>1800 55 1800</Link>.
              </Typography>
              <Typography paragraph>
                A free, private and confidential, telephone and online counselling service specifically for young people
                aged between 5 and 25.
              </Typography>
            </Box>
          </Box>

          <Accordion
            elevation={0}
            className={classes.accordion}
            classes={{
              root: classes.MuiAccordionroot,
            }}>
            <AccordionSummary
              style={{ padding: 0 }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="other-resources"
              id="other-resources-header">
              <Typography color="primary" variant="h4">
                Other resources for parents and young people
              </Typography>
            </AccordionSummary>

            <AccordionDetails style={{ padding: 0 }}>
              <Box>
                <CredibleResources subheading={true} />

                <Typography variant="h5" className={classes.subtitle}>
                  Resources to support LGBTQIA+ young people
                </Typography>

                <Box mb={4}>
                  <Typography variant="h6">
                    <ExtLink href="https://headspace.org.au/eheadspace/spaces/community/372936/" color="primary">
                      qheadspace
                    </ExtLink>
                  </Typography>
                  <Typography paragraph>A peer led online chat for LGBTQIA+ young people in Australia.</Typography>
                </Box>

                <Box mb={4}>
                  <Typography variant="h6">
                    <ExtLink href="https://www.qlife.org.au" color="primary">
                      QLife
                    </ExtLink>
                  </Typography>
                  <Typography paragraph>
                    Australia-wide, anonymous, peer support and referral for people wanting to talk about issues
                    relating to sexuality, identity, gender, bodies, feelings, or relationships. Phone and web-chat
                    available.
                  </Typography>
                </Box>

                <Typography variant="h5" className={classes.subtitle}>
                  Resources for financial, accommodation, and legal advice
                </Typography>

                <Typography paragraph>
                  The services listed below can provide referral information for families struggling with accommodation,
                  finances, family violence, and other specialist services in each state.
                </Typography>

                <Box mb={4}>
                  <Typography variant="h6">
                    <ExtLink href="https://yla.org.au" color="primary">
                      Youth Law Australia
                    </ExtLink>
                  </Typography>
                  <Typography paragraph>
                    Free, confidential legal information and advice for young people under 25, Australia wide.
                  </Typography>

                  <Typography paragraph>Services by state:</Typography>

                  {legalServices.map((item: LegalServices, index) => {
                    return (
                      <Box width={xs ? '100%' : md ? '70%' : '50%'}>
                        <Accordion
                          elevation={0}
                          key={index}
                          className={classes.accordion}
                          classes={{
                            root: classes.MuiAccordionroot,
                          }}>
                          <AccordionSummary
                            style={{ padding: 0 }}
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header">
                            <Typography>{item.state}</Typography>
                          </AccordionSummary>

                          <AccordionDetails style={{ padding: 0 }}>
                            <Typography>
                              <ExtLink href={item.url}>{`${item.name}.`}</ExtLink>{' '}
                              <ExtLink href={`tel: ${item.phone}`}>{`Ph: ${item.phone}`}</ExtLink>
                            </Typography>
                          </AccordionDetails>
                        </Accordion>
                      </Box>
                    );
                  })}
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>
      </Container>
    </SitePage>
  );
}
